import GenericHead from 'components/GenericHead';
import RichResults from 'components/RichResults';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { Genre } from 'state/Genres/types';
import { get } from 'lodash-es';
import { getScaledImageUrlById } from 'utils/url';
import { Helmet } from 'react-helmet';
import { Market } from 'state/Location/types';
import { link as playerLink, meta as playerMeta } from 'heads/player';
import { STATION_TYPE } from 'constants/stationTypes';
import { translateKeywords } from 'utils/metaUtils';
import { ViewName } from 'views/PageBody';
import {
  WIDGET_DIMENSIONS,
  WidgetDimensions,
} from 'constants/widgetDimensions';

export type Props = {
  band: string;
  callLetters: string;
  city: string;
  description: string;
  freq: string;
  genre: Genre | Record<string, any>;
  id: string;
  isSubSection?: boolean;
  logo: string;
  market: Market;
  metaTitle?: string;
  pagePath: string;
  seedId: string | number;
  seedType: string;
  siteUrl: string;
  stateAbbreviation: string;
  stationName: string;
  website: string;
};

export default function Head({
  band,
  callLetters,
  city,
  description,
  freq,
  genre,
  id,
  isSubSection = false,
  logo,
  market,
  metaTitle,
  pagePath,
  seedId,
  seedType,
  siteUrl,
  stateAbbreviation,
  stationName,
  website,
}: Props) {
  const translate = useTranslate();

  const title = metaTitle || stationName;

  const keywords = translateKeywords(
    translate,
    'iHeartRadio, iHeart, Listen, Live, Music, Talk, Radio, Online, Streaming, Free',
  );
  const dynamicKeywords = translate(
    '{stationName}, {stateAbbreviation}, {city}, {genre}',
    {
      city,
      genre: get(genre, 'name'),
      stateAbbreviation,
      stationName,
    },
  );

  const { height, fixedWidth: width }: WidgetDimensions =
    WIDGET_DIMENSIONS.LIVE ?? {};

  let imageUrl = logo;
  if (logo && logo.startsWith('//')) {
    imageUrl = `https:${logo}`;
  } else if (!logo) {
    imageUrl = getScaledImageUrlById(seedId as any as number, seedType);
  }

  return (
    <>
      <GenericHead
        deeplink={`goto/live/${seedId}`}
        description={stationName ? description : undefined}
        image={imageUrl}
        legacyDeeplink={`play/live/${seedId}`}
        metaDescription={description}
        ogType={isSubSection ? 'website' : 'music.radio_station'}
        title={title}
        twitterCard="product"
      />
      <Helmet
        link={playerLink({ pagePath: pagePath ?? '', siteUrl, title })}
        meta={[
          ...(isSubSection
            ? []
            : playerMeta({ height, pagePath: pagePath ?? '', siteUrl, width })),
          { content: `${keywords}, ${dynamicKeywords}`, name: 'keywords' },
          {
            content: translate('Genre'),
            name: 'twitter:label1',
          },
          { content: get(genre, 'name'), name: 'twitter:data1' },
          {
            content: translate('Location'),
            name: 'twitter:label2',
          },
          { content: get(market, 'name'), name: 'twitter:data2' },
        ]}
      />
      <RichResults
        meta={{
          broadcastDisplayName: stationName,
          broadcastFrequency: { band, frequency: freq },
          callLetters,
          description,
          id,
          keywords,
          market,
          seedType: STATION_TYPE.LIVE,
          stationName,
          url: pagePath,
          website,
        }}
        type={ViewName.LiveProfile}
      />
    </>
  );
}
