import LiveDescription from 'components/LiveDescription';
import LiveDropdown from 'components/Dropdown/LiveDropdown';
import resolveStation from 'state/Player/resolvers';
import SectionHeader from 'primitives/Typography/Headings/SectionHeader';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { getCurrentStationSimilars } from 'state/Live/selectors';
import { isEqual } from 'lodash-es';
import { mapSeedStation } from 'web-player/mapper';
import { Props } from './types';
import { Similar } from 'components/SimilarSection/types';
import { SeeAllSimilarSection as SimilarSection } from 'components/SimilarSection';
import { useSelector } from 'react-redux';

const LiveSimilarSection: FunctionComponent<Props> = ({
  isAbTest,
  itemSelectedLocation,
  playedFrom,
  singleRow,
  stationName,
  stationType,
  tilesInRow,
  url = '',
}) => {
  const translate = useTranslate();
  // IHRWEB-17456 - we need a shallow equality check to ensure we don't run the effect unnecessarily
  const similarIds = useSelector(getCurrentStationSimilars, isEqual);
  const [similars, setSimilars] = useState<Array<Similar>>([]);

  useEffect(() => {
    if (similarIds.length) {
      Promise.all(
        similarIds.map(id =>
          resolveStation({
            partialLoad: true,
            playedFrom: String(playedFrom),
            stationId: id,
            stationType,
          }).then(mapSeedStation),
        ),
      ).then(newSimilars => setSimilars(newSimilars.filter(s => s.id)));
    }
  }, [similarIds]);

  const subTitle = useCallback(
    ({ description = '' }: Similar) => <LiveDescription text={description} />,
    [],
  );

  const header = isAbTest ? (
    <SectionHeader hasMobileBottomLink>
      {translate('If you like {stationName}...', { stationName })}
    </SectionHeader>
  ) : (
    translate('Similar Stations')
  );
  return (
    <SimilarSection
      canPlaylist
      dropdown={LiveDropdown}
      hasBottomMargin
      header={header}
      isAbTest={isAbTest}
      itemSelectedLocation={itemSelectedLocation}
      playedFrom={playedFrom}
      similars={similars}
      singleRow={singleRow}
      subTitle={subTitle}
      suppressFirstOfType
      tilesInRow={tilesInRow}
      url={url}
    />
  );
};

export default LiveSimilarSection;
