import PodcastShowMoreTiles from 'components/PodcastTiles/PodcastShowMoreTiles';
import Section from 'components/Section';
import SectionHeader from 'primitives/Typography/Headings/SectionHeader';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { Podcast } from 'state/Podcast/types';
import { TILE_RES } from 'components/MediaServerImage';

type Props = {
  relatedPodcasts: Array<Podcast>;
  stationName?: string;
  tilesInRow: number;
};

function RelatedPodcasts({
  relatedPodcasts,
  stationName = '',
  tilesInRow,
}: Props) {
  const translate = useTranslate();

  const header = (
    <SectionHeader hasMobileBottomLink>
      {translate(`{stationName} Podcasts`, { stationName })}
    </SectionHeader>
  );

  return relatedPodcasts.length ? (
    <Section
      dataTest="related-podcast-section"
      hasBorder={false}
      hasExtraPadding={false}
      hasMobileBottomLink
      header={header}
      suppressFirstOfType
    >
      <PodcastShowMoreTiles
        hasBottomMargin
        imgWidth={TILE_RES}
        itemSelectedLocation="home|podcasts|tile"
        podcasts={relatedPodcasts}
        tilesInRow={tilesInRow}
      />
    </Section>
  ) : null;
}

export default RelatedPodcasts;
