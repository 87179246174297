import ContentDetail from './primitives/ContentDetail';
import ContentDetailLink from './primitives/ContentDetailLink';
import Image from 'components/Image';
import Info from './primitives/Info';
import InfoWrapper from './primitives/InfoWrapper';
import LiveBox from './primitives/LiveBox';
import MoreIcon from 'styles/icons/More';
import NavLink from 'components/NavLink';
import RoundImageWrapper from './primitives/RoundImageWrapper';
import Section from 'components/Section';
import Title from './primitives/Title';
import Truncate from 'components/Truncate';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import Wrapper from './primitives/Wrapper';
import {
  addOps,
  buildImageUrl,
  buildUrl,
} from 'utils/mediaServerImageMaker/mediaServerImageUrl';
import { buildOpsString, fit } from 'utils/mediaServerImageMaker/opsString';
import { FunctionComponent } from 'react';
import { Props } from './types';
import { THUMB_FIT, THUMB_RES } from 'components/MediaServerImage';

const OnAir: FunctionComponent<Props> = ({
  coming,
  clickedFrom,
  mediaServerUrl,
  name,
  siteUrl,
  target,
  thumbnail,
}) => {
  const translate = useTranslate();
  const imgSrc = thumbnail
    ? buildImageUrl(
        buildUrl({ mediaServerUrl, siteUrl }, thumbnail),
        addOps(buildOpsString(fit(...THUMB_FIT))()),
      )()
    : null;

  return (
    <Section
      hasBorder={false}
      hasExtraPadding={false}
      header={translate('On Air Schedule')}
      isHidden={!name}
    >
      <Wrapper>
        <ContentDetail key="first-dj">
          <RoundImageWrapper>
            <Image alt={name} src={imgSrc!} />
          </RoundImageWrapper>
          <InfoWrapper>
            <Title>
              <Truncate lines={1}>{name}</Truncate>
            </Title>
            <LiveBox>{translate('Live')}</LiveBox>
          </InfoWrapper>
          {target && (
            <ContentDetailLink>
              <NavLink
                clickedFrom={clickedFrom}
                supressDeepLink
                target="_blank"
                to={target}
              >
                <MoreIcon />
              </NavLink>
            </ContentDetailLink>
          )}
        </ContentDetail>
        {coming.slice(0, 2).map(dj => (
          <ContentDetail key={dj.name}>
            <RoundImageWrapper>
              <Image
                alt={dj.name}
                src={
                  dj.destination &&
                  `${dj.destination.thumbnail}?ops=fit(${encodeURIComponent(
                    `${THUMB_RES},${THUMB_RES}`,
                  )})`
                }
              />
            </RoundImageWrapper>
            <InfoWrapper>
              <Title>
                <Truncate lines={1}>{dj.name}</Truncate>
              </Title>
              <Info>
                <Truncate lines={1}>{`${dj.start}-${dj.stop}`}</Truncate>
              </Info>
            </InfoWrapper>
            {dj?.destination?.href && (
              <ContentDetailLink>
                <NavLink
                  clickedFrom={clickedFrom}
                  supressDeepLink
                  target="_blank"
                  to={dj.destination.href}
                >
                  <MoreIcon />
                </NavLink>
              </ContentDetailLink>
            )}
          </ContentDetail>
        ))}
      </Wrapper>
    </Section>
  );
};

export default OnAir;
