import FilledButton from 'primitives/Buttons/FilledButton';
import Follow from 'styles/icons/Follow';
import FollowButtonWrapper from 'primitives/Hero/FollowButtonWrapper';
import HeroContent from './primitives/HeroContent';
import HeroDescription from './primitives/HeroDescription';
import HeroPlayButton from 'components/Player/HeroPlayButton';
import HeroPlaySection from 'primitives/Hero/HeroPlaySection';
import HeroThumbnail from 'primitives/Hero/HeroThumbnail';
import HeroTitle from 'views/Live/LiveHero/primitives/HeroTitle';
import InfoWrapper from 'primitives/Hero/InfoWrapper';
import OutlinedButton from 'primitives/Buttons/OutlinedButton';
import PlayButtonContainer from 'components/Player/PlayButtonContainer';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import PlayerStateProxy from 'components/Player/PlayerState/PlayerStateProxy';
import SocialButtonWrapper from 'primitives/Hero/SocialButtonWrapper';
import SocialConnect from 'views/Live/LiveHero/SocialConnect';
import SocialShare from 'views/Live/LiveHero/SocialShare';
import ThumbnailWrapper from 'primitives/Hero/ThumbnailWrapper';
import trackers from 'trackers';
import useDynamicBackground from 'hooks/useDynamicBackground';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { Events } from 'modules/Analytics';
import { fit, resize, urlOps } from 'utils/mediaServerImageMaker/opsString';
import { FunctionComponent } from 'react';
import { getIsLoggedOut } from 'state/Session/selectors';
import { resolveAltText } from 'components/Hero/helpers';
import { showFavoriteChangedGrowl } from 'state/UI/actions';
import { useDispatch, useSelector } from 'react-redux';

import type { Props } from 'views/Live/LiveHero/types';

const PlayButton = PlayerStateProxy(PlayButtonContainer);

const LiveHero: FunctionComponent<Props> = ({
  description,
  isFavorite,
  logo,
  mediaServerUrl,
  noLogo,
  seedId,
  seedType,
  social,
  stationContact,
  stationName,
  subscriptionType,
  toggleFavoriteStation,
}) => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const loggedOut = useSelector(getIsLoggedOut);
  const [loadImage, backgroundCss] = useDynamicBackground(50);

  const toggleFavorite = () => {
    // Appboy Tracking event both registered and anonymous user
    trackers.track(isFavorite ? Events.UnsaveStation : Events.SaveStation, {
      name: stationName,
      type: 'live',
      identifier: seedId,
    });

    toggleFavoriteStation({ stationId: seedId });

    // only show growl if authenticated
    if (!loggedOut)
      dispatch(
        showFavoriteChangedGrowl({
          isFavorite: !isFavorite,
          name: stationName,
        }),
      );
  };

  return (
    <div
      css={theme => ({ background: theme.colors.black.primary })}
      data-test="hero-container"
    >
      <div css={backgroundCss}>
        <HeroContent>
          <ThumbnailWrapper leftAligned>
            {noLogo ? null : (
              <HeroThumbnail
                alt={resolveAltText(
                  stationName,
                  seedType,
                  'Background Thumbnail',
                )}
                aspectRatio={1}
                crossOrigin
                newHero
                onLoad={loadImage}
                ops={[
                  urlOps(logo || mediaServerUrl),
                  fit(480, 480),
                  resize(0, 195),
                ]}
                src={logo || mediaServerUrl}
                whiteBackground
              />
            )}
          </ThumbnailWrapper>
          <InfoWrapper>
            {stationName && <HeroTitle>{stationName}</HeroTitle>}
            {description && <HeroDescription>{description}</HeroDescription>}
            <HeroPlaySection>
              <div>
                <PlayButton
                  ButtonComponent={HeroPlayButton}
                  newHero
                  playedFrom={PLAYED_FROM.PROF_LIVE_PLAY}
                  seedId={seedId}
                  stationId={seedId}
                  stationType={seedType}
                  subscriptionType={subscriptionType}
                />
              </div>
              <FollowButtonWrapper newHero>
                {isFavorite ? (
                  <FilledButton
                    data-test="follow-button"
                    onClick={() => toggleFavorite()}
                    size="small"
                    styleType="light"
                  >
                    {translate('Following')}
                  </FilledButton>
                ) : (
                  <OutlinedButton
                    data-test="follow-button"
                    onClick={() => toggleFavorite()}
                    size="small"
                    styleType="light"
                  >
                    <Follow />
                    {translate('Follow')}
                  </OutlinedButton>
                )}
              </FollowButtonWrapper>
              <SocialButtonWrapper>
                {social?.url ? (
                  <>
                    <SocialConnect
                      stationContact={stationContact}
                      supportsConnect={social?.supportsConnect}
                    />
                    <SocialShare {...social} />
                  </>
                ) : null}
              </SocialButtonWrapper>
            </HeroPlaySection>
          </InfoWrapper>
        </HeroContent>
      </div>
    </div>
  );
};

export default LiveHero;
