import Section from 'components/Section';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import WideTile from 'components/WideTile/WideTile';
import WideTilesList from 'components/WideTile/primitives/WideTilesList';
import { getLeads } from 'state/Live/selectors';
import { Data as ItemSelectedData } from 'modules/Analytics/helpers/itemSelected';
import { STATION_TYPE } from 'constants/stationTypes';
import { useSelector } from 'react-redux';
import type { FunctionComponent } from 'react';

const LivePromotionSection: FunctionComponent = () => {
  const leads = useSelector(getLeads)?.slice(0, 3) ?? [];
  const translate = useTranslate();

  return leads.length ? (
    <Section
      hasBorder={false}
      hasExtraPadding={false}
      hasMobileBottomLink
      header={translate('Contests and Promotions')}
      suppressFirstOfType
    >
      <WideTilesList count={leads.length}>
        {leads.map(({ title, id, img_uri: imgUrl, url }) => {
          const checkedId = id.includes('/') ? id.slice(1) : id;
          const itemSelected = {
            location: 'live_profile|promotion_section|tile',
            name: title,
            type: STATION_TYPE.LIVE,
          } as ItemSelectedData;

          return (
            <WideTile
              imgUrl={imgUrl}
              itemSelected={itemSelected}
              key={checkedId}
              target="_blank"
              title={title}
              url={url}
            />
          );
        })}
      </WideTilesList>
    </Section>
  ) : null;
};

export default LivePromotionSection;
