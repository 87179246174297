import differenceInMinutes from 'date-fns/difference_in_minutes';
import Section from 'components/Section';
import SongRow from './SongRow';
import useMount from 'hooks/useMount';
import usePlayerState from 'contexts/PlayerState/usePlayerState';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import Wrapper from 'views/Live/OnAirSchedule/primitives/Wrapper';
import { getRecentlyPlayedTracks } from 'state/Live/selectors';
import { requestRecentlyPlayed } from 'state/Live/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import type { FunctionComponent } from 'react';
import type { PlayedFrom } from 'modules/Analytics/types';

type Props = {
  itemSelectedLocation: string;
  playedFrom: PlayedFrom;
  seedId: number;
};

const NewRecentlyPlayed: FunctionComponent<Props> = ({
  itemSelectedLocation,
  playedFrom,
  seedId,
}) => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const playerState = usePlayerState();
  const recentlyPlayedTracks = useSelector(getRecentlyPlayedTracks);
  const [lastPlayed, setLastPlayed] = useState(playerState?.trackId);

  useMount(() => {
    dispatch(requestRecentlyPlayed(seedId));
  });

  useEffect(() => {
    if (playerState?.trackId && lastPlayed !== playerState?.trackId) {
      setLastPlayed(playerState?.trackId);
      dispatch(requestRecentlyPlayed(seedId));
    }
  }, [dispatch, lastPlayed, playerState?.trackId, seedId]);

  const cutoffTime = 30;

  if (recentlyPlayedTracks.length > 0) {
    const timeDiff = differenceInMinutes(
      new Date(),
      new Date(recentlyPlayedTracks[0].endTime * 1000),
    );

    return recentlyPlayedTracks.length && timeDiff <= cutoffTime ? (
      <Section
        dataTest="recently-played-section"
        hasBorder={false}
        hasExtraPadding={false}
        header={translate('Recently Played')}
      >
        <Wrapper>
          {recentlyPlayedTracks.map(track => (
            <SongRow
              itemSelectedLocation={itemSelectedLocation}
              key={track.id}
              playedFrom={playedFrom}
              track={track}
              translate={translate}
            />
          ))}
        </Wrapper>
      </Section>
    ) : null;
  } else {
    return null;
  }
};

export default NewRecentlyPlayed;
